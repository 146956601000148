.ks-progress-bar {
    position: relative;
    height: 1rem;
    width: 15rem;
    border: 2px solid $ks-color-success;
    border-radius: .8rem;
    overflow: hidden;   
}

.ks-progress-bar__progress {
    @extend .ks-flex-center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $ks-color-success;
}
