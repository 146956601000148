@import "mixins/responsive";
@import "mixins/rtl";

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;500&display=swap');

// BASE CONFIGURATION
html,
body {
  font-family: 'Heebo', sans-serif;
  font-size: 10px;
  line-height: 1.25;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #F2FAFC;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Heebo', sans-serif;
  margin: 0;
}

/* scrool bar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #999;
}

// COLORS
$ks-white: #fff;
$ks-background: #F2FAFC;
$ks-background-tint: #f3f3f4;

$ks-color-primary: #ff4619;
$ks-color-accent: #00e2e8;
$ks-color-accent-1: #FF4619;
$ks-color-error: #D60000;
$ks-color-warning: #e28b00;
$ks-color-success: #38aa47;

$ks-color-main-text: #202627;
$ks-color-link: $ks-color-primary;
$ks-color-border: #D6EAF0;


.ks-color-primary {
  color: $ks-color-primary !important;
}
.ks-color-text {
  color: $ks-color-main-text !important;
}
.ks-color-accent {
  color: $ks-color-accent !important;
}
.ks-color-accent-1 {
  color: $ks-color-accent-1 !important;
}
.ks-color-error {
  color: $ks-color-error !important;
}
.ks-color-warning {
  color: $ks-color-warning !important;
}
.ks-color-success {
  color: $ks-color-success !important;
}

.ks-link {
  color: $ks-color-primary !important;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

// TYPOGRAPHY
$ks-font-xxl: 4rem;
$ks-font-xl: 3rem;
$ks-font-lg: 2.4rem;
$ks-font-md: 1.8rem;
$ks-font-sm: 1.4rem;
$ks-font-xs: 1.2rem;

.ks-main-title {
  font-size: $ks-font-xl;
  color: $ks-color-primary;
  line-height: 1.1;
}

.ks-section-title {
  font-size: $ks-font-lg;
  color: $ks-color-primary;
  line-height: 1.1;
}

.ks-paragraph {
  font-family: 'Heebo', sans-serif;
}

.ks-font-xxl {
  font-size: $ks-font-xxl;
}
.ks-font-xl {
  font-size: $ks-font-xl;
}
.ks-font-lg {
  font-size: $ks-font-lg;
}
.ks-font-md {
  font-size: $ks-font-md;
}
.ks-font-sm {
  font-size: $ks-font-sm;
}
.ks-footnote {
  font-size: $ks-font-xs;
}

.ks-bold {
  font-weight: 700;
}
.ks-medium {
  font-weight: 500;
}
.ks-normal {
  font-weight: 300;
}
.ks-light {
  font-weight: 100;
}

.ks-text-start {
  @include text-start();
}
.ks-text-end {
  @include text-end();
}
.ks-text-center {
  text-align: center;
}

.ks-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

// SPACING
.ks-mb-0 {
  margin-bottom: 0 !important;
}
.ks-mb-5 {
  margin-bottom: 0.5rem;
}
.ks-mb-10 {
  margin-bottom: 1rem;
}
.ks-mb-20 {
  margin-bottom: 2rem;
}
.ks-mb-30 {
  margin-bottom: 3rem;
}
.ks-mb-40 {
  margin-bottom: 4rem;
}
.ks-mb-50 {
  margin-bottom: 5rem;
}

.ks-mt-0 {
  margin-top: 0 !important;
}
.ks-mt-5 {
  margin-top: 0.5rem;
}
.ks-mt-10 {
  margin-top: 1rem;
}
.ks-mt-20 {
  margin-top: 2rem;
}
.ks-mt-30 {
  margin-top: 3rem;
}
.ks-mt-40 {
  margin-top: 4rem;
}
.ks-mt-50 {
  margin-top: 5rem;
}
.ks-mt-80 {
  margin-top: 8rem;
}
.ks-mt-100 {
  margin-top: 10rem;
}

// Z_INDEX
$ks-z-0: 0;
$ks-z-1: 10;
$ks-z-2: 20;
$ks-z-3: 30;
$ks-z-4: 40;
$ks-z-5: 50;

.ks-z-0 {
  z-index: $ks-z-0;
}
.ks-z-1 {
  z-index: $ks-z-1;
}
.ks-z-2 {
  z-index: $ks-z-2;
}
.ks-z-3 {
  z-index: $ks-z-3;
}
.ks-z-4 {
  z-index: $ks-z-4;
}
.ks-z-5 {
  z-index: $ks-z-5;
}

// BUTTONS

.ks-button {
  height: 2.6rem;
  padding: .3rem 3rem;
  border: 0 none;
  font-family: 'Heebo', sans-serif;
  color: $ks-white;
  background-color: $ks-color-primary;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  @extend .ks-flex-vertical-center;
}

.ks-button--lg {
  @extend .ks-button;
  height: 4rem;
  font-size: $ks-font-lg;
}

.ks-button--md {
  @extend .ks-button;
  height: 3rem;
  padding: .3rem 1rem;
  font-size: $ks-font-md;
}

.ks-button--accent {
  background-color: $ks-color-primary;
  transition: background-color .3s ease;
  &:hover,
  &:active {
    background-color: $ks-color-accent-1;
  }
}

.ks-button--outline {
  border: 2px solid $ks-color-success;
  background-color: transparent;
  color: $ks-color-success;
  transition: background-color .3s ease, color .3s ease;
  &:hover,
  &:active {
    background-color: $ks-color-success;
    color: $ks-white;
  }

  &:disabled {
    border-color: rgba($ks-color-success, .5);
    color: rgba($ks-color-success, .5);
    cursor: default;
  }
}

// LISTS

.ks-ol {
  li {
    list-style-type: hebrew;
    list-style-position: outside;
    margin: 0 2rem 1rem 0;
    padding-right: 2rem;
  }
}

.ks-ul {
  li {
    list-style-type: disc;
    list-style-position: outside;
    margin: 0 2rem 1rem 0;
    padding-right: 2rem;
  }
}

// LAYOUT
$ks-header-height: 6rem;

.ks-application {
  font-family: 'Heebo', sans-serif;
  background-color: $ks-background;
  font-size: $ks-font-sm;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.ks-container {
  width: calc(100% - 2em);
  margin: 0 auto;
  @include breakpoint(sm) {
    width: calc(100% - 4em);
  }
  @include breakpoint(md) {
    width: calc(100% - 10em);
  }
  @include breakpoint(xl) {
    max-width: 120rem;
  }
}

.ks-card {
  position: relative;
  border: 1px solid $ks-color-border;
  background-color: $ks-white;
}

.ks-card__content {
  padding: 2rem;
}

%stretch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ks-full-width {
  position: relative;
  left: 50%;
  width: 100vw;
  transform: translate3d(-50%, 0, 0);
}

.ks-flex-spacer {
  flex: 1 1 auto;
}

.ks-flex-start {
  display: flex;
  flex-direction: row;
}

.ks-flex-end {
  display: flex;
  flex-direction: row-reverse;
}

.ks-flex-column {
  display: flex;
  flex-direction: column;
}

.ks-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.ks-flex-vertical-center {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.ks-flex-horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ks-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ks-2-columns-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include breakpoint(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.ks-3-columns-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include breakpoint(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ks-4-columns-grid {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 2.25rem), 1fr));
}

.ks-grid-10-gap {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.ks-grid-20-gap {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  @include breakpoint(max-sm) {
    grid-row-gap: 2rem;
  }
}

.ks-grid-40-gap {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  @include breakpoint(max-sm) {
    grid-row-gap: 2rem;
  }
}

.ks-grid-60-gap {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  @include breakpoint(max-sm) {
    grid-row-gap: 2rem;
  }
}
