.ks-details-list__item {
    @extend .ks-flex-space-between;
    @include linear-gradient(90deg, $ks-color-main-text, $ks-color-main-text 1px, transparent 1px, transparent 10px);
    background-repeat: repeat-x;
    background-position-y: 100%;
    background-size: 10px 1px;
    &:not(:last-child) {
        margin-bottom: .7rem;
    }
    font-size: $ks-font-sm;
    @include breakpoint(max-sm) {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }
}

.ks-details-list__term,
.ks-details-list__value {
    position: relative;
    z-index: $ks-z-1;
    background-color: $ks-background;
    margin-bottom: -.3rem;
}

.ks-details-list__term  {
    padding-left: .5rem;
}
.ks-details-list__value { 
    padding-right: .5rem;
 }