@mixin breakpoint($point) {
  @if $point == xl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 700px) {
      @content;
    }
  } @else if $point == max-xl {
    @media (max-width: 1399px) {
      @content;
    }
  } @else if $point == max-md {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $point == max-sm {
    @media (max-width: 699px) {
      @content;
    }
  } @else if $point == only-md {
    @media (min-width: 1024px) and (max-width: 1399px) {
      @content;
    }
  }
}
