@mixin margin-end($value) {
    .ks-ltr & {
        margin-right: $value;
    }
    
    .ks-rtl & {
        margin-left: $value;
    }
}

@mixin padding-end($value) {
    .ks-ltr & {
        padding-right: $value;
    }
    
    .ks-rtl & {
        padding-left: $value;
    }
}

@mixin position-end($value) {
    .ks-ltr & {
        right: $value;
    }
    
    .ks-rtl & {
        left: $value;
    }
}

@mixin text-end() {
    .ks-ltr & {
        text-align: right;
    }
    
    .ks-rtl & {
        text-align: left;
    }
}

@mixin margin-start($value) {
    .ks-ltr & {
        margin-left: $value;
    }
    
    .ks-rtl & {
        margin-right: $value;
    }
}

@mixin padding-start($value) {
    .ks-ltr & {
        padding-left: $value;
    }
    
    .ks-rtl & {
        padding-right: $value;
    }
}

@mixin position-start($value) {
    .ks-ltr & {
        left: $value;
    }
    
    .ks-rtl & {
        right: $value;
    }
}

@mixin text-start() {
    .ks-ltr & {
        text-align: left;
    }
    
    .ks-rtl & {
        text-align: right;
    }
}