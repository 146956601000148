/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;500&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  font-family: "Heebo", sans-serif;
  font-size: 10px;
  line-height: 1.25;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #F2FAFC;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Heebo", sans-serif;
  margin: 0;
}

/* scrool bar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #999;
}

.ks-color-primary {
  color: #ff4619 !important;
}

.ks-color-text {
  color: #202627 !important;
}

.ks-color-accent {
  color: #00e2e8 !important;
}

.ks-color-accent-1 {
  color: #FF4619 !important;
}

.ks-color-error {
  color: #D60000 !important;
}

.ks-color-warning {
  color: #e28b00 !important;
}

.ks-color-success {
  color: #38aa47 !important;
}

.ks-link {
  color: #ff4619 !important;
}
.ks-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.ks-main-title {
  font-size: 3rem;
  color: #ff4619;
  line-height: 1.1;
}

.ks-section-title {
  font-size: 2.4rem;
  color: #ff4619;
  line-height: 1.1;
}

.ks-paragraph {
  font-family: "Heebo", sans-serif;
}

.ks-font-xxl {
  font-size: 4rem;
}

.ks-font-xl {
  font-size: 3rem;
}

.ks-font-lg {
  font-size: 2.4rem;
}

.ks-font-md {
  font-size: 1.8rem;
}

.ks-font-sm {
  font-size: 1.4rem;
}

.ks-footnote {
  font-size: 1.2rem;
}

.ks-bold {
  font-weight: 700;
}

.ks-medium {
  font-weight: 500;
}

.ks-normal {
  font-weight: 300;
}

.ks-light {
  font-weight: 100;
}

.ks-ltr .ks-text-start {
  text-align: left;
}
.ks-rtl .ks-text-start {
  text-align: right;
}

.ks-ltr .ks-text-end {
  text-align: right;
}
.ks-rtl .ks-text-end {
  text-align: left;
}

.ks-text-center {
  text-align: center;
}

.ks-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.ks-mb-0 {
  margin-bottom: 0 !important;
}

.ks-mb-5 {
  margin-bottom: 0.5rem;
}

.ks-mb-10 {
  margin-bottom: 1rem;
}

.ks-mb-20 {
  margin-bottom: 2rem;
}

.ks-mb-30 {
  margin-bottom: 3rem;
}

.ks-mb-40 {
  margin-bottom: 4rem;
}

.ks-mb-50 {
  margin-bottom: 5rem;
}

.ks-mt-0 {
  margin-top: 0 !important;
}

.ks-mt-5 {
  margin-top: 0.5rem;
}

.ks-mt-10 {
  margin-top: 1rem;
}

.ks-mt-20 {
  margin-top: 2rem;
}

.ks-mt-30 {
  margin-top: 3rem;
}

.ks-mt-40 {
  margin-top: 4rem;
}

.ks-mt-50 {
  margin-top: 5rem;
}

.ks-mt-80 {
  margin-top: 8rem;
}

.ks-mt-100 {
  margin-top: 10rem;
}

.ks-z-0 {
  z-index: 0;
}

.ks-z-1 {
  z-index: 10;
}

.ks-z-2 {
  z-index: 20;
}

.ks-z-3 {
  z-index: 30;
}

.ks-z-4 {
  z-index: 40;
}

.ks-z-5 {
  z-index: 50;
}

.ks-button, .ks-button--md, .ks-button--lg {
  height: 2.6rem;
  padding: 0.3rem 3rem;
  border: 0 none;
  font-family: "Heebo", sans-serif;
  color: #fff;
  background-color: #ff4619;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.ks-button--lg {
  height: 4rem;
  font-size: 2.4rem;
}

.ks-button--md {
  height: 3rem;
  padding: 0.3rem 1rem;
  font-size: 1.8rem;
}

.ks-button--accent {
  background-color: #ff4619;
  transition: background-color 0.3s ease;
}
.ks-button--accent:hover, .ks-button--accent:active {
  background-color: #FF4619;
}

.ks-button--outline {
  border: 2px solid #38aa47;
  background-color: transparent;
  color: #38aa47;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.ks-button--outline:hover, .ks-button--outline:active {
  background-color: #38aa47;
  color: #fff;
}
.ks-button--outline:disabled {
  border-color: rgba(56, 170, 71, 0.5);
  color: rgba(56, 170, 71, 0.5);
  cursor: default;
}

.ks-ol li {
  list-style-type: hebrew;
  list-style-position: outside;
  margin: 0 2rem 1rem 0;
  padding-right: 2rem;
}

.ks-ul li {
  list-style-type: disc;
  list-style-position: outside;
  margin: 0 2rem 1rem 0;
  padding-right: 2rem;
}

.ks-application {
  font-family: "Heebo", sans-serif;
  background-color: #F2FAFC;
  font-size: 1.4rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.ks-container {
  width: calc(100% - 2em);
  margin: 0 auto;
}
@media (min-width: 700px) {
  .ks-container {
    width: calc(100% - 4em);
  }
}
@media (min-width: 1024px) {
  .ks-container {
    width: calc(100% - 10em);
  }
}
@media (min-width: 1400px) {
  .ks-container {
    max-width: 120rem;
  }
}

.ks-card {
  position: relative;
  border: 1px solid #D6EAF0;
  background-color: #fff;
}

.ks-card__content {
  padding: 2rem;
}

.ks-full-width {
  position: relative;
  left: 50%;
  width: 100vw;
  transform: translate3d(-50%, 0, 0);
}

.ks-flex-spacer {
  flex: 1 1 auto;
}

.ks-flex-start {
  display: flex;
  flex-direction: row;
}

.ks-flex-end {
  display: flex;
  flex-direction: row-reverse;
}

.ks-flex-column {
  display: flex;
  flex-direction: column;
}

.ks-flex-space-between, .ks-details-list__item {
  display: flex;
  justify-content: space-between;
}

.ks-flex-vertical-center, .ks-button, .ks-button--lg, .ks-button--md {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.ks-flex-horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ks-flex-center, .ks-progress-bar__progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ks-2-columns-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 700px) {
  .ks-2-columns-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.ks-3-columns-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 700px) {
  .ks-3-columns-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ks-4-columns-grid {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - 2.25rem), 1fr));
}

.ks-grid-10-gap {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.ks-grid-20-gap {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
@media (max-width: 699px) {
  .ks-grid-20-gap {
    grid-row-gap: 2rem;
  }
}

.ks-grid-40-gap {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
}
@media (max-width: 699px) {
  .ks-grid-40-gap {
    grid-row-gap: 2rem;
  }
}

.ks-grid-60-gap {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
}
@media (max-width: 699px) {
  .ks-grid-60-gap {
    grid-row-gap: 2rem;
  }
}

.ks-details-list__item {
  background: #202627;
  background: -webkit-linear-gradient(0deg, #202627, #202627 1px, transparent 1px, transparent 10px);
  background: linear-gradient(90deg, #202627, #202627 1px, transparent 1px, transparent 10px);
  background-repeat: repeat-x;
  background-position-y: 100%;
  background-size: 10px 1px;
  font-size: 1.4rem;
}
.ks-details-list__item:not(:last-child) {
  margin-bottom: 0.7rem;
}
@media (max-width: 699px) {
  .ks-details-list__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.ks-details-list__term,
.ks-details-list__value {
  position: relative;
  z-index: 10;
  background-color: #F2FAFC;
  margin-bottom: -0.3rem;
}

.ks-details-list__term {
  padding-left: 0.5rem;
}

.ks-details-list__value {
  padding-right: 0.5rem;
}

.ks-progress-bar {
  position: relative;
  height: 1rem;
  width: 15rem;
  border: 2px solid #38aa47;
  border-radius: 0.8rem;
  overflow: hidden;
}

.ks-progress-bar__progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #38aa47;
}